import { HttpClient, HttpHeaders } from '@angular/common/http';
import { afterNextRender, inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APIs } from '@app/core/configs/apis.config';
import { environment } from '../../../../environments/enviroment';
import * as jwt_decode from 'jwt-decode';
import { BehaviorSubject, map, of, take } from 'rxjs';
import { StorageKeys } from '../models/storage.interface';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl: string = environment.apiUrl;
  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });
  private localStorageService = inject(LocalStorageService);
  private readonly router = inject(Router);
  static isAuthorized: any;

  isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  isAuthenticated$ = this.isAuthenticatedSubject.asObservable();
  show401Toaster: boolean = false;



  // Define a BehaviorSubject for can_edit
  onEditSubject = new BehaviorSubject<boolean>(false);
  onEdit$ = this.onEditSubject.asObservable();

  hasEditSubject = new BehaviorSubject<boolean>(false);
  hasEdit$ = this.hasEditSubject.asObservable();


  private storageKey = 'userData';

  setUserData(data: any): void {
    localStorage.setItem(this.storageKey, JSON.stringify(data));
  }

  getUserData(): any {
    const storedData = localStorage.getItem(this.storageKey);
    return storedData ? JSON.parse(storedData) : null;
  }

  clearUserData(): void {
    localStorage.removeItem(this.storageKey);
  }

  constructor(private http: HttpClient) {
    afterNextRender(() => {
      const permissions = this.getPermissionsFromToken(
        this.localStorageService.get(StorageKeys.token),
      )?.map(Number);

      const canEdit = permissions?.includes(5050) ?? false;
      this.hasEditSubject.next(canEdit);
      this.setCanEdit(canEdit);
    });
  }

  getUaePassToken(lang: string) {
    return this.http.get(
      this.apiUrl + `${APIs.Identity.LoginUaePass}?language=${lang}`,
    );
  }

  refreshToken(accessToken: any, refreshToken: any) {
    return this.http.post(this.apiUrl + APIs.Identity.RefreshToken, {
      accessToken,
      refreshToken,
    });
  }
  loginUaePassToken(authCode: string) {
    //debugger;
    return this.http
      .get(this.apiUrl + APIs.Identity.GetLoginToken + '/' + authCode, {
        headers: this.headers,
        observe: 'body',
      })
      .pipe(
        take(1),
        map((res: any) => {
          if (res.accessToken) {
            this.isAuthenticatedSubject.next(true);
          }
          return res;
        }),
      );
  }

  rgisterUaePassToken() {
    return this.http.get(this.apiUrl + APIs.Identity.RegisterUaePass);
  }

  logoutUaePass() {
    return this.http.get(this.apiUrl + APIs.Identity.LogoutUaePass);
  }

  logOut() {
    this.isAuthenticatedSubject.next(false);
    this.setCanEdit(false); // Update canEdit on logout
    this.localStorageService.remove(StorageKeys.user);
    this.localStorageService.remove(StorageKeys.token);
    this.localStorageService.remove(StorageKeys.refreshToken);
    this.router.navigate(['/auth/sign-in']);
  }

  isAuthorized() {
    return this.getCurrentUser() && this.isAuthenticated() ? true : false;
  }

  isAuthenticated(): boolean {
    const token = this.localStorageService.get(StorageKeys.token);
    return token ? true : false;
  }

  getCurrentUser() {
    const user = this.localStorageService.get(StorageKeys.user);
    return user;
  }

  // Function to decode the token
  decodeToken(token: string): any {
    if (token) {
      try {
        return jwt_decode.jwtDecode(token);
      } catch (error) {
        console.error('Invalid token:', error);
        return null;
      }
    }
  }

  // Example function to get permissions from the token
  getPermissionsFromToken(token: string): string[] | null {
    const decodedToken = this.decodeToken(token);

    if (decodedToken && decodedToken.permissions) {
      return decodedToken.permissions; // Assumes permissions are an array in the token
    }
    return null;
  }

  // Method to set canEdit status
  private setCanEdit(canEdit: boolean) {
    localStorage.setItem('can_edit', JSON.stringify(canEdit));
    // this.canEditSubject.next(canEdit);
  }

  hasEditPermission(): boolean {
    return this.onEditSubject.value;
  }

  toggleEditMode() {
    if (this.onEditSubject.value) {
      this.onEditSubject.next(false);
    } else {
      this.onEditSubject.next(true);
    }
  }

  postConfirm(payload: any) {
    return this.http.post(this.apiUrl + APIs.Identity.postConfirm, payload);
  }
}
